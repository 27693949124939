import React from 'react';
import styled from 'styled-components'
import css from '@styled-system/css'
import { Link } from "gatsby"
import { Grid, GridItem, Typography, Box, Accordion, AccordionItem } from '@mintuz/marvel'
import { Layout } from '../components/layout';
import { HeaderStyled, HeaderContentStyled } from '../components/HeaderStyled';
import { GhostButton } from '../components/GhostButton'
import { Logo } from '../components/Logo';

const GridWrap = styled.div`
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;

    ${css({
        paddingLeft: [4],
        paddingRight: [4],
    })}
`

const GridWrapSmall = styled(GridWrap)`
    max-width: 700px;

    p, h2 {
        ${css({
            marginBottom: [4],
        })}
    }
`

export default () => {
    return (
        <Layout>
            <HeaderStyled>
                <GridWrap>
                    <Grid>
                        <GridItem width={[1 / 1]}>
                            <Box pt={[[4]]} display="flex" justifyContent="space-between" alignItems="center">
                                <Link to="/"><Logo /></Link>
                                <GhostButton as={Link} to="/workouts" variant="secondary">Free Workouts</GhostButton>
                            </Box>
                        </GridItem>
                        <GridItem width={[1 / 1]}>
                            <Box display="flex" justifyContent="center" alignItems="center" height="40vh">
                                <HeaderContentStyled color="white">
                                    <Typography as="h1" variant="displayOne" mb={[6]}>Terms of Service</Typography>
                                </HeaderContentStyled>
                            </Box>
                        </GridItem>
                    </Grid>
                </GridWrap>
            </HeaderStyled>
            <Box pt="100px" pb={[6]}>
                <GridWrapSmall>
                    <p>Last updated: <strong>September 14th, 2021</strong></p>

                    <h2>Short version: Workout Plan Bot is not a medical app</h2>

                    <p>Workout Plan Bot is not a medical app and the developer (Adam Bulmer) assumes no liability for any disadvantages or damages resulting from the use of Workout Plan Bot.</p>

                    <p>Any displayed information in this app and your consequent actions are at your discretion and your sole responsibility. Never rely on the information from this app and always consult your doctor.</p>

                    <h2>Full Version</h2>

                    <p>These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with the Workout Plan Bot mobile application and website (the "Service") operated by Adam Bulmer. ("us", "we", "I" or "our").</p>
                    <p>Please read these Terms and Conditions carefully before using our Workout Plan Bot mobile application and website (the "Service").</p>
                    <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
                    <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>

                    <h3>Content</h3>

                    <p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content that you post to the Service, including its legality, reliability, and appropriateness.</p>

                    <p>By posting Content to the Service, you grant us the right and license to use, modify, perform, display, reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights.</p>

                    <p>You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or company.</p>

                    <h3>Intellectual Property</h3>

                    <p>The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Adam Bulmer. The Service is protected by copyright and other laws of both United Kingdom and foreign countries.</p>

                    <h3>Links To Other Web Sites</h3>

                    <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by Adam Bulmer.</p>

                    <p>Adam Bulmer has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Adam Bulmer shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>

                    <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>

                    <h3>Limitation Of Liability</h3>

                    <p>In no event shall Adam Bulmer, nor his partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>

                    <h3>Disclaimer</h3>

                    <p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>

                    <p>Adam Bulmer does not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>

                    <p>Workout Plan Bot offers health and fitness information and is designed for educational purposes only. You should not rely on this information as a substitute for, nor does it replace, professional medical advice, diagnosis, or treatment. If you have any concerns or questions about your health, you should always consult with a physician or other health-care professional.</p>

                    <p>Do not disregard, avoid or delay obtaining medical or health related advice from your health-care professional because of something you may have read on Workout Plan Bot. The use of any information provided on the Service is solely at your own risk. Adam Bulmer assumes no liability for any disadvantages or damages resulting from the use of Workout Plan Bot.</p>

                    <h3>Governing Law</h3>

                    <p>These Terms shall be governed and construed in accordance with the laws of United Kingdom, without regard to its conflict of law provisions.</p>
                    <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>

                    <h3>Changes</h3>

                    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                    <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>

                    <h3>Workout Plan Bot App Premium</h3>

                    <p>Workout Plan Bot App Premium is an opt-in upgrade that unlocks various features of the app.</p>

                    <p>Workout Plan Bot App users can opt-in to a one-time fee for premium features. Payment will be charged to iTunes Account at confirmation of purchase.</p>

                    <h3>Contact Us</h3>

                    <p>If you have any questions about these Terms, please contact us.</p>
                </GridWrapSmall>
            </Box>
        </Layout>
    )
}
